<template>
  <Card class="px-3 py-3 flex flex-col w-full gap-3">
    <template>
      <div class="flex justify-start items-center gap-2">
        <h1 class="font-bold text-lg leading-6 tracking-0015 text-flame">Section 3:</h1>
        <h2 class="font-bold text-lg leading-6 tracking-0015 text-darkPurple">
          Previous Efforts to Avert a Below-Standard Performance
        </h2>
      </div>
    </template>
    <template>
      <Table
        :headers="headers"
        :items="items"
        aria-label="PerformanceSections"
        :has-number="true"
      >
        <template v-slot:item="{ item }">
          <div v-if="item.performanceIssue" class="flex justify-start items-center gap-2">
            <span class="text-darkPurple font-normal text-base leading-5">
              {{ item.data.performanceIssue === '' ? '----' :  item.data.performanceIssue}}
            </span>
            <div
              class="text-flame text-base cursor-pointer"
              @click="uploadModal = !uploadModal"
              v-if="item.data.performanceIssue !== ''"
            >
              <Icon icon-name="attachment" size="xs" />
            </div>
          </div>
          <div
            v-if="item.correction"
            class="font-normal text-base leading-5 text-darkPurple"
          >
            {{item.data.correction}}
          </div>
              <div
                v-if="item.dispPeriod"
                class="flex flex-col justify-center items-start gap-2 whitespace-nowrap mb-4 w-36"
              >
                <div
                  class="flex justify-between items-center gap-2 whitespace-nowrap bg-ghostWhite px-3 py-2 rounded-md"
                  v-if="item.data.dispPeriod[0].startDate !== ''"
                >
                  <span class="text-darkPurple text-sm leading-5">
                    {{
                      item.data.dispPeriod[0].startDate !== ""
                        ? $DATEFORMAT(
                            new Date(item.data.dispPeriod[0].startDate),
                            "MMMM dd, yyyy"
                          )
                        : ""
                    }}
                  </span>
                </div>
                <div
                  class="flex justify-between items-center gap-2 whitespace-nowrap bg-ghostWhite px-3 py-2 rounded-md"
                  v-if="item.data.dispPeriod[1].endDate !== ''"
                >
                  <span class="text-darkPurple text-sm leading-5">
                    {{
                      item.data.dispPeriod[1].endDate !== ""
                        ? $DATEFORMAT(
                            new Date(item.data.dispPeriod[1].endDate),
                            "MMMM dd, yyyy"
                          )
                        : ""
                    }}
                  </span>
                </div>
                </div>
        </template>
      </Table>
    </template>
  </Card>
</template>

<script>
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Icon from "@/components/Icon";

export default {
  name: "BelowPerformance",
  components: {
    Card,
    Table,
    Icon,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    message: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      uploadModal: false,
      headers: [
        { title: "Performance Issue", value: "performanceIssue", width: 30 },
        { title: "Previous Correctional Efforts", value: "correction" },
        { title: "Period", value: "dispPeriod" },
      ],
      current: {},
      payloadTimeline: {},
    };
  },

  methods: {
    setStart(value, timeline, prevTimeline) {
      this.current = timeline;
      this.payloadTimeline = prevTimeline;

      this.current[0].startDate = this.formatDate(value);
      this.payloadTimeline[0].startDate = value;
    },

    setEnd(value, timeline, prevTimeline) {
      this.current = timeline;
      this.payloadTimeline = prevTimeline;

      this.current[1].endDate = this.formatDate(value);
      this.payloadTimeline[1].endDate = value;
    },

    formatDate(value) {
      const month = this.$DATEFORMAT(new Date(value), "MM");
      const day = this.$DATEFORMAT(new Date(value), "dd");
      const year = this.$DATEFORMAT(new Date(value), "yyyy");

      return `${year}-${month}-${day}`;
    },

    setPayload() {
      const payload = this.items;

      this.$emit('set', payload);
    }
  },

  mounted() {
        setInterval(() => {
          this.setPayload();
        }, 10000);
  }
};
</script>
<style lang="scss">
.tracking-0015 {
  letter-spacing: 0.015em;
}
tbody {
  tr {
    background: white !important;
    border-bottom: 1px dashed #878e99 !important;
  }
  tr:nth-last-child(-n + 2) {
    border-bottom: 0 !important;
  }
}
.width-272px {
  width: 272px;
}

.vdp-datepicker__calendar {
  z-index: 9999;
}
</style>
