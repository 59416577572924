<template>
  <Card class="px-3 py-3 flex flex-col w-full gap-5">
    <template>
      <div class="flex justify-start items-center gap-2">
        <h1 class="font-bold text-lg leading-6 tracking-0015 text-flame">Section 1:</h1>
        <h2 class="font-bold text-lg leading-6 tracking-0015 text-darkPurple">Specific Indicators of Below Standard Performance</h2>
      </div>
    </template>
    <template>
      <Table
        :headers="headers"
        :items="items"
        aria-label="PerformanceSections"
        :has-number="true"
      >
        <template v-slot:item="{ item }">
          <div v-if="item.performanceIssue" class="flex justify-start items-center gap-2">
            <span class="text-darkPurple font-normal text-base leading-5">
              {{ item.data.performanceIssue }}
            </span>
            <div class="text-flame text-base">
              <Icon icon-name="attachment" size="xs" />
            </div>
          </div>
          <div v-if="item.period" class="flex flex-col justify-start items-start">
            <span class="text-base leading-5 font-normal text-darkPurple whitespace-nowrap">
              From {{ $DATEFORMAT( new Date(item.data.period[0].startDate), "MMMM dd, yyyy") }}
              -
              To {{ $DATEFORMAT( new Date(item.data.period[1].endDate), "MMMM dd, yyyy") }}
            </span>
          </div>
          <div v-if="item.impact" class="font-normal text-base leading-5">
            <span class="text-darkPurple">
              {{ item.data.impact }}
            </span>
          </div>
        </template>
      </Table>
    </template>
  </Card>
</template>

<script>
  import Card from "@scelloo/cloudenly-ui/src/components/card";
  import Table from "@scelloo/cloudenly-ui/src/components/table";
  import Icon from "@/components/Icon";

  export default {
    name: "SpecificIndicators",
    components: {
      Card,
      Table,
      Icon
    },
    props: {
      items: {
        type: Array,
        required: true,
        default: (() => [])
      }
    },
    data() {
      return {
        headers: [
          { title: "Performance Issue", value: "performanceIssue" },
          { title: "Period", value: "period" },
          { title: "Impact", value: "impact" },
        ],
      };
    },
  };
</script>
<style lang="scss">
  .tracking-0015 {
    letter-spacing: 0.015em;
  }
  tbody {
    tr {
      background: white !important;
      border-bottom: 1px dashed #878E99 !important;
    }
    tr:nth-last-child(-n+2) {
      border-bottom: 0 !important;
    }
  }
</style>

