<template>
  <div class="w-full pt-5 pb-5">
    <template>
      <div class="flex items-center">
        <div class="border-r border-romanSilver h-6 flex">
          <BackButton label="Back" @onClick="$router.back()" variant="secondary" />
        </div>
        <h1 class="text-xl text-left font-extrabold mx-3">
          Perf. Improvement Plan
        </h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </template>

    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div v-else>

    <template>
      <div class="m-3">
          <PreliminaryDetails
            :employee-data="preliminaryData.employeeData"
            :line-manager="preliminaryData.lineManager"
            :performance="preliminaryData.performance"
            :previous-interviews="preliminaryData.previousInterviews"
            :pip-initiation-date="preliminaryData.pipInitiationDate"
            :pip-duration="preliminaryData.pipDuration"
            :interview-days="preliminaryData.previousDays"
            :review-sessions="preliminaryData.reviewSessions"
          />
      </div>
    </template>
    <template>
      <div class="w-full flex justify-center mt-5">
        <div class="w-6/12 border-b border-romanSilver" />
      </div>
    </template>
    <template>
      <div class="m-3 mt-10 flex flex-col gap-5">
       <SpecificIndicators :items="section1Data" />
        <SatisfactoryPerformance :items="section2Data" :ratings="Ratings" :criteria="successCriteria" />
        <BelowPerformance :items="section3Data" />
        <ImprovePerformance :items="section4Data" />
        <EmployeeEngagement
          :is-manager="true"
          :header="section5Data.header"
          :title="section5Data.title"
          :comment="section5Data.comments"
        />
        <SignaturesComments :items="section6Data" />
      </div>
    </template>
    <template>
      <div class="flex justify-between items-center m-3 mt-32 -mb-20">
        <div class="flex justify-evenly items-center gap-5 text-base font-semibold leading-5">
          <Button class="bg-dynamicBackBtn text-white px-2 w-48 h-11 flex justify-center">
            Notify Involved Parties
          </Button>
          <Button class="bg-white px-2 w-48 h-11 flex justify-center text-dynamicBackBtn border border-solid border-dynamicBackBtn">
            Send Message
          </Button>
          <Button class="text-darkPurple px-2 w-48 h-11 flex justify-center">
            Cancel
          </Button>
        </div>
        <div class="flex items-center justify-center">
          <Icon icon-name="icon-printer" class="text-darkPurple" size="xs" />
          <Button width="110px;height:44px;">Print a Copy</Button>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>

<script>
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
  import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
  import Button from "@/components/Button";
  import PreliminaryDetails from "../Components/PreliminaryDetails";
  import SpecificIndicators from "../Components/SpecificIndicators";
  import SatisfactoryPerformance from "../Components/SatisfactoryPerformance";
  import BelowPerformance from "../Components/BelowPerformance";
  import ImprovePerformance from "../Components/ImprovePerformance";
  import EmployeeEngagement from "../Components/EmployeeEngagement";
  import SignaturesComments from "../Components/SignaturesComments";

  export default {
    name: 'PerformanceImprovementPlan',
    components: {
      BackButton,
      Breadcrumb,
      Button,
      PreliminaryDetails,
      SpecificIndicators,
      SatisfactoryPerformance,
      BelowPerformance,
      ImprovePerformance,
      EmployeeEngagement,
      SignaturesComments
    },
  data() {
    return {
      loading: true,
      pipId: "",
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance Improvement",
          href: "performance-improvement",
          id: "PerformanceImprovement",
        },
        {
          disabled: false,
          text: "Improvement FY2021",
          href: "performance-improvement-FY2021",
          id: "PerformanceImprovementFY2021",
        },
        {
          disabled: false,
          text: "Performance Improvement Plan",
          href: "performance-improvement-plan",
          id: "PerformanceImprovementPlan",
        },
      ],
      preliminaryData: {
        employeeData: {
          avatar: "",
          name: "",
          id: "",
          designation: "",
          department: "",
          dateEmployed: "",
          supervisoryFunction: "",
        },
        lineManager: {
          avatar: "",
          name: "",
          designation: "",
        },
        performance: [],
        instructions:
          "This form is to be completed by the manager of an employee whose job performance is considered to be below standard. The purpose of this procedure is to be sure that employee understands exactly how his/her job performance is deficient, and to assist him/her in achieving prompt and significant improvements in his/her job performance, if that is possible.",
      },
      managerId: "",
      userId: "",
      section3Message: `Indicate the specific action taken
          (a) to advise the employee that his/her performance was (or was becoming) unsatisfactory,
          (b) to assist him/her in making the necessary improvements in his/her performance, and
          (c) to be sure that he/she understood the necessity of achieving such improvements.
          Consider whether any managerial action may have led the employee to assume that he/she had already succeeded in achieving the necessary improvements. If no record no record of the previous appraisal is attached, explain why.`,
      section4Message: `Indicate action to be taken by the employee and/or the manager in order to achieve the standard of performance necessary to       eliminate this employee’s Development Plan Status. The exact period during which the employee must improve will be stated. Note: During this period the manager will make frequent informal appraisals of the employee’s progress toward achieving the standards listed in section 2, and will advise the employee of the results of these appraisals`,
      section5Message: `This section is prepared by the manager in the presence of the employee at the close of the interview, during which the manager will have read the contents of this form to the employee, and/or allowed the employee to read it. This section is a summary of what the manager regards as the most significant aspects of the interview. It should include any action plans (in addition to those listed in section 4) agreed upon by the manager and the employee during the interview for the purpose of helping to improve the employee’s performance.`,
      section1Data: [],
      section2Data: [],
      section3Data: [],
      section4Data: [],
      section5Data: {
        header: "Section 5:",
        title: "Employee Engagement by Line Manager",
        subtitle2: "Remark/Comment From Manager",
        comments: "",
        id: "",
      },
      section6Data: [],
      successCriteria: "",
      Ratings: [],
      pipSignatures: [],
      pipSignatureDetails: [],
      payloadStructure: {
        orgId: "",
        appraiseeId: "",
        pipSettingId: "",
        status: "",
        notify: false,
        sharedWithEmployee: false,
        sectionOne: [],
        sectionTwo: [],
        sectionThree: [],
        sectionFour: [],
        sectionFive: {},
        sectionSix: {},
      },

      validateSection1: [],
    };
  },

  methods: {
    setSatisfactory(data) {
      let newData = data.map(({ dispPeriod, ...rest }) => ({
        ...rest,
      }));

      const validation = data.every((item) => item.objective || item.weight);

      if (!validation) {
        newData = [];
      }

      this.payloadStructure.sectionTwo = newData;
    },

    setIndicators(data) {
      let newData = data.map(({ dispPeriod, ...rest }) => ({
        ...rest,
      }));

      const validation = data.every((item) => item.performanceIssue);

      if (!validation) {
        newData = [];
      }

      this.payloadStructure.sectionOne = newData;
    },

    setBelowPerformance(data) {
      const validation = data.every(
        (item) => item.performanceIssue || item.correction
      );

      if (!validation) {
        // eslint-disable-next-line no-param-reassign
        data = [];
      }

      this.payloadStructure.sectionThree = data;
    },

    setImprovePerformance(data) {
      let newData = data.map(({ dispPeriod, ...rest }) => ({
        ...rest,
      }));

      const validation = data.every((item) => item.performanceIssue);

      if (!validation) {
        // eslint-disable-next-line no-param-reassign
        newData = [];
      }

      this.payloadStructure.sectionFour = newData;
    },

    setEngagementPerformance(data) {
      const isEmpty = Object.values(data).every((x) => x === null || x === "");

      if (isEmpty) {
        this.payloadStructure.sectionFive = {};
      }
      this.payloadStructure.sectionFive = data;
    },

    setSignatures(data) {
      this.payloadStructure.sectionSix.signatories = [];
      data.filter((item) => {
        if (item.signature !== "") {
          if (item.id === "") {
            // eslint-disable-next-line no-param-reassign
            delete item.id;
          } else {
            this.payloadStructure.sectionSix.id = item.id;
          }
          this.payloadStructure.sectionSix.comments = item.comments;
          this.payloadStructure.sectionSix.date = item.date;
          this.payloadStructure.sectionSix.signature = item.signature;

          const payload = {
            employeePipId: this.$route.query.pip,
            signatoryId: item.userId,
            comments: item.comments,
            date: item.date,
            signId: item.id,
            signature: item.signature,
          };

          this.$_signPIPImprovement(payload)
            .then((response) => {
              this.$toasted.success(response.data.message, { duration: 5000 });
            })
            .catch((err) => {
              this.$toasted.error(err.response.data.message, {
                duration: 5000,
              });
            });
        }
        this.payloadStructure.sectionSix.signatories.push(item.userId);

        return {};
      });
    },

    submitPlan(state) {
      this.payloadStructure.orgId = this.$orgId;
      this.payloadStructure.appraiseeId = this.$route.params.id;

      if (state) {
        this.payloadStructure.status = "draft";
      } else {
        this.payloadStructure.status = "active";
      }

      if (this.pipId) {
        this.payloadStructure.employeePipId = this.pipId;
      }

      this.$_setEmployeePIP(this.payloadStructure)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.$router.back();
        })
        .catch((error) => {
          this.$toasted.error(error.data.message, { duration: 5000 });
        });
    },

    sharePlan(type) {
      this.payloadStructure.orgId = this.$orgId;
      this.payloadStructure.appraiseeId = this.$route.params.id;
      this.payloadStructure.status = "active";

      if (type === "employee") {
        this.payloadStructure.sharedWithEmployee = true;
      } else {
        this.payloadStructure.notify = true;
        this.payloadStructure.sharedWithEmployee = false;
      }

      if (this.pipId) {
        this.payloadStructure.employeePipId = this.pipId;
      }

      this.$_setEmployeePIP(this.payloadStructure)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.$router.back();
        })
        .catch((error) => {
          this.$toasted.error(error.data.message, { duration: 5000 });
        });
    },

    addIndicators() {
      this.section1Data.push({
        issue: "",
        period: { from: "", to: "" },
        impact: "",
      });
    },

    addNewSatisfactory() {
      this.section2Data.push({
        objective: "",
        period: [
          {
            startDate: "",
          },
          {
            endDate: "",
          },
        ],
        dispPeriod: [
          {
            startDate: "",
          },
          {
            endDate: "",
          },
        ],
        kpis: this.setCriteria(""),
        weight: "",
      });
    },

    setCriteria(kpis) {
      const criteriaData = [];
      const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);

      this.Ratings = sortedRatings;

      this.Ratings.map((rating) => {
        criteriaData.push({
          performanceRatingId: rating.id,
          criteria: kpis !== "" ? kpis : "",
        });
        return {};
      });

      return criteriaData;
    },

    getEmployeePerformance() {
      this.$_getEmployeePreliminary(this.$route.params.id).then((response) => {
        const { userDetails } = response.data;

        this.preliminaryData.employeeData.avatar = userDetails.photo;
        this.preliminaryData.employeeData.name = `${userDetails.fname} ${userDetails.lname}`;
        this.preliminaryData.employeeData.id = "---";
        this.preliminaryData.employeeData.designation = userDetails.designation;
        this.preliminaryData.employeeData.dateEmployed = "---";
        this.preliminaryData.employeeData.department = userDetails.function;
        this.preliminaryData.employeeData.supervisoryFunction =
          userDetails.lineManager.function;
        this.preliminaryData.lineManager.avatar = userDetails.lineManager.photo;
        this.preliminaryData.lineManager.name = `${userDetails.lineManager.fname} ${userDetails.lineManager.lname}`;
        this.preliminaryData.lineManager.designation =
          userDetails.lineManager.designation;

        this.managerId = userDetails.lineManager.managerId;
        this.userId = userDetails.userId;

        const { performanceScore } = response.data;

        performanceScore.map((score, index) => {
          this.preliminaryData.performance.push({
            status: this.setStatus(index, score.score),
            year: `${score.date}`,
            value: score.score,
          });
          return {};
        });
      });
    },

    setStatus(position, score) {
      let status;

      if (position === 0) {
        status = "default";
      }

      if (position > 0) {
        const prevScore = this.preliminaryData.performance[position - 1].value;
        const currScore = score;

        if (currScore > prevScore) {
          status = "increasing";
        } else {
          status = "decreasing";
        }
      }

      return status;
    },

    formatDate(value) {
      let initialDate = "";

      if (value) {
        initialDate = new Date(value).toISOString().replace(/.\d+Z$/g, "");

        return this.$DATEFORMAT(new Date(initialDate), "MMMM dd, yyyy");
      }

      return "";
    },

    formatSecDate(value) {
      const month = this.$DATEFORMAT(new Date(value), "MM");
      const day = this.$DATEFORMAT(new Date(value), "dd");
      const year = this.$DATEFORMAT(new Date(value), "yyyy");

      return `${year}-${month}-${day}`;
    },

    getActivePIP() {
      this.preliminaryData.reviewSessions = [];

      this.$_getActivePIP(this.$route.params.templateId).then((response) => {
        this.successCriteria = response.data.pip.pipOutcome;
        this.payloadStructure.pipSettingId = response.data.pip.id;

        const pipReview = response.data.pip.PerformanceReviewSessions;

        pipReview.forEach((session) => {
          this.preliminaryData.reviewSessions.push({
            reviewSessionId: session.id,
            date: "",
          });
        });

        this.getEmployeePIP();
      });
    },

    resetDate(value) {
      if (value) {
        const dateValue = value;

        const split = dateValue.split("T00:00:00.000Z");

        return `${split[0]}T13:00:00.000Z`;
      }
      return "";
    },

    getEmployeePIP() {
      this.section1Data = [];
      this.section2Data = [];
      this.section3Data = [];
      this.section4Data = [];
      this.section6Data = [];

      this.$_getEmployeePIP(this.$route.params.id)
        .then((response) => {
          this.loading = false;
          const details = response.data.pipDetails;
          this.preliminaryData.previousInterviews =
            details.hasDonePreviousInterview;
          this.preliminaryData.pipInitiationDate = details.pipInitiationDate;
          this.preliminaryData.pipStartDate = this.resetDate(
            details.pipStartDate
          );
          this.preliminaryData.pipEndDate = this.resetDate(details.pipEndDate);
          this.preliminaryData.pipDuration = {
            from: details.pipStartDate,
            to: details.pipEndDate,
          };

          details.reviewSessionDate.map((session, index) => {
            // eslint-disable-next-line no-param-reassign
            session.date = this.resetDate(session.date);

            this.preliminaryData.reviewSessions[index].date = session.date;
            return {};
          });

          this.pipId = response.data.pipDetails.id;
          this.preliminaryData.previousDays = this.resetDate(
            details.previousInterviewDate
          );

          if (details.below_standard_performance.length === 0) {
            this.section1Data.push({
              performanceIssue: "",
              impact: "",
              documentUrl: "",
              period: [
                {
                  startDate: "",
                },
                {
                  endDate: "",
                },
              ],
            });
          }
          details.below_standard_performance.map((item) => {
            this.section1Data.push({
              id: item.id,
              performanceIssue: item.performanceIssue,
              impact: item.impact,
              documentUrl: item.performanceIssueUploadUrl,
              period: [
                {
                  startDate: this.resetDate(item.period[0].startDate),
                },
                {
                  endDate: this.resetDate(item.period[1].endDate),
                },
              ],
            });
            return {};
          });

          if (details.employee_improvements_task.length === 0) {
            this.section2Data.push({
              objective: "",
              period: [
                {
                  startDate: "",
                },
                {
                  endDate: "",
                },
              ],
              dispPeriod: [
                {
                  startDate: "",
                },
                {
                  endDate: "",
                },
              ],
              kpis: this.setCriteria(""),
              weight: "",
            });
          }
          details.employee_improvements_task.map((item) => {
            this.section2Data.push({
              id: item.id,
              objective: item.objective,
              period: item.timeLine,
              dispPeriod: [
                {
                  startDate: this.resetDate(item.timeLine[0].startDate),
                },
                {
                  endDate: this.resetDate(item.timeLine[1].endDate),
                },
              ],
              kpis: item.criterias,
              weight: item.weight,
            });
            return {};
          });

          if (details.correctional_efforts.length === 0) {
            details.below_standard_performance.map((item) => {
              this.section3Data.push({
                performanceIssue: item.performanceIssue,
                correction: "",
                documentUrl: "",
                period: [
                  {
                    startDate: "",
                  },
                  {
                    endDate: "",
                  },
                ],
                dispPeriod: [
                  {
                    startDate: "",
                  },
                  {
                    endDate: "",
                  },
                ],
              });
              return {};
            });
          }
          details.correctional_efforts.map((item) => {
            this.section3Data.push({
              id: item.id,
              performanceIssue: item.performanceIssue,
              correction: item.previousCorrectionalEffort,
              documentUrl: item.performanceIssueUploadUrl,
              dispPeriod: [
                {
                  startDate: this.resetDate(item.period[0].startDate),
                },
                {
                  endDate: this.resetDate(item.period[1].endDate),
                },
              ],
            });
            return {};
          });

          if (details.employee_improvement_action_plans.length === 0) {
            details.below_standard_performance.map((item) => {
              this.section4Data.push({
                performanceIssue: item.performanceIssue,
                actionPlan: "",
                documentUrl: "",
                period: [
                  {
                    startDate: "",
                  },
                  {
                    endDate: "",
                  },
                ],
                dispPeriod: [
                  {
                    startDate: "",
                  },
                  {
                    endDate: "",
                  },
                ],
                responsibleParty: [],
              });
              return {};
            });
          }
          details.employee_improvement_action_plans.map((item) => {
            this.section4Data.push({
              id: item.id,
              performanceIssue: item.performanceIssue,
              documentUrl: item.performanceIssueUploadUrl,
              dispPeriod: [
                {
                  startDate: this.resetDate(item.timeline[0].startDate),
                },
                {
                  endDate: this.resetDate(item.timeline[1].endDate),
                },
              ],
              actionPlan: item.actionPlan,
              responsibleParty: item.responsibleParties,
            });
            return {};
          });

          this.section5Data.comments =
            details.employee_engagement_line_manager.remark;
          this.section5Data.id = details.employee_engagement_line_manager.id;

          this.pipSignatureDetails = details.pip_signatures_comments;
        })
        .catch((err) => {
          this.section1Data.push({
            performanceIssue: "",
            impact: "",
            documentUrl: "",
            period: [
              {
                startDate: "",
              },
              {
                endDate: "",
              },
            ],
            dispPeriod: [
              {
                startDate: "",
              },
              {
                endDate: "",
              },
            ],
          });

          this.section2Data.push({
            objective: "",
            period: [
              {
                startDate: "",
              },
              {
                endDate: "",
              },
            ],
            dispPeriod: [
              {
                startDate: "",
              },
              {
                endDate: "",
              },
            ],
            kpis: this.setCriteria(""),
            weight: "",
          });

          this.section3Data.push({
            performanceIssue: "",
            correction: "",
            documentUrl: "",
            period: [
              {
                startDate: "",
              },
              {
                endDate: "",
              },
            ],
            dispPeriod: [
              {
                startDate: "",
              },
              {
                endDate: "",
              },
            ],
          });

          this.section4Data.push({
            performanceIssue: "",
            actionPlan: "",
            documentUrl: "",
            period: [
              {
                startDate: "",
              },
              {
                endDate: "",
              },
            ],
            dispPeriod: [
              {
                startDate: "",
              },
              {
                endDate: "",
              },
            ],
            responsibleParty: [],
          });
          return err;
        });

      this.getPIPSignatures();
    },

    getPIPSignatures() {
      this.$_getPIPSignatures(this.$route.query.pip)
        .then((response) => {
          this.pipSignatures = response.data.data.pipSignatures;
          this.getSignatories();
        })
        .catch((err) => {
          this.getSignatories();
          return err;
        });
    },

    setSignatureDetails(userId, type) {
      let signature;
      let comments;
      let date;
      let idValue;

      if (type === "signature") {
        if (this.pipSignatures) {
          this.pipSignatures.filter((signatory) => {
            if (signatory.userId === userId) {
              signature = this.$getInitials(
                `${signatory.fname} ${signatory.lname}`
              );
            }
            return {};
          });
        }
        return signature || "";
      }

      if (type === "comments") {
        if (this.pipSignatures) {
          this.pipSignatureDetails.filter((signatory) => {
            if (signatory.signatoryId === userId) {
              comments = signatory.comments;
            }
            return {};
          });
        }
        return comments || "";
      }

      if (type === "date") {
        if (this.pipSignatures) {
          this.pipSignatureDetails.filter((signatory) => {
            if (signatory.signatoryId === userId) {
              date = signatory.date;
            }
            return {};
          });
        }
        return date || "";
      }

      if (type === "id") {
        if (this.pipSignatures) {
          this.pipSignatureDetails.filter((signatory) => {
            if (signatory.signatoryId === userId) {
              idValue = signatory.id;
            }
            return {};
          });
        }
        return idValue || "";
      }

      return "";
    },

    getSignatories() {
      this.$_getPIPSignatories(this.$route.params.templateId, "?signatory=pip").then(
        (response) => {
          const signatories = response.data.data;
          this.loading = false;

          signatories.filter((item) => {
            if (item.userId !== this.managerId) {
              this.section6Data.push({
                id: this.setSignatureDetails(item.userId, "id"),
                photo: item.photo,
                name: `${item.fname} ${item.lname}`,
                position: item.designation,
                signature: this.setSignatureDetails(item.userId, "signature"),
                userId: item.userId,
                comments: this.setSignatureDetails(item.userId, "comments"),
                date: this.setSignatureDetails(item.userId, "date"),
              });
            }
            return {};
          });

          this.section6Data.push({
            id: this.setSignatureDetails(this.managerId, "id"),
            photo: this.preliminaryData.lineManager.avatar,
            name: this.preliminaryData.lineManager.name,
            position: this.preliminaryData.lineManager.designation,
            signature: this.setSignatureDetails(this.managerId, "signature"),
            userId: this.managerId,
            comments: this.setSignatureDetails(this.managerId, "comments"),
            date: this.setSignatureDetails(this.managerId, "date"),
          });
        }
      );
    },
  },

  mounted() {
    this.getEmployeePerformance();
    this.$_getPerfomanceRating().then((response) => {
      this.Ratings = response.data.PerformanceRatings;
    });
    this.getActivePIP();
  },
};
</script>

<style scoped>
.submitted {
  background: rgba(19, 181, 106, 0.15);
  color: #27ae60;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}
.not_submitted {
  background: rgba(234, 60, 83, 0.15);
  color: #ea3c53;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}
</style>
