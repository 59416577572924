<template>
  <Card class="px-3 py-3 flex flex-col w-full gap-3">
    <template>
      <div class="flex justify-start items-center gap-2">
        <h1 class="font-bold text-lg leading-6 tracking-0015 text-flame">Section 4:</h1>
        <h2 class="font-bold text-lg leading-6 tracking-0015 text-darkPurple">
          Action Plan to Improve Performance
        </h2>
      </div>
    </template>
    <template>
      <Table
        :headers="headers"
        :items="items"
        aria-label="PerformanceSections"
        :has-number="true"
      >
        <template v-slot:item="{ item }">
          <div v-if="item.performanceIssue" class="flex justify-start items-center gap-2">
            <span class="text-darkPurple font-normal text-base leading-5 w-72">
              {{ item.data.performanceIssue === '' ? '----' :  item.data.performanceIssue}}
            </span>
            <div class="text-flame text-base cursor-pointer">
              <Icon icon-name="attachment" size="xs" />
            </div>
          </div>
          <div v-if="item.actionPlan" class="font-normal text-base leading-5 text-darkPurple">
            {{ item.data.actionPlan }}
          </div>
          <div v-if="item.responsibleParty" class="flex items-center whitespace-nowrap gap-3">
            <div class="flex flex-col justify-start items-start gap-2">
              <div v-for="(value, i) in item.data.responsibleParty" :key="`${value}-${i}`">
                <div class="flex gap-2 bg-ghostWhite p-2 rounded-md whitespace-nowrap">
                  <span class="text-sm leading-5 font-normal text-darkPurple">
                    {{ value }}
                  </span>
                </div>
              </div>
            </div>
          </div>
              <div
                v-if="item.dispPeriod"
                class="flex flex-col justify-center items-start gap-2 whitespace-nowrap mb-4 w-36"
              >
                <div
                  class="flex justify-between items-center gap-2 ml-5 whitespace-nowrap bg-ghostWhite px-3 py-2 rounded-md"
                  v-if="item.data.dispPeriod[0].startDate !== ''"
                >
                  <span class="text-darkPurple text-sm leading-5">
                    {{
                      item.data.dispPeriod[0].startDate !== ""
                        ? $DATEFORMAT(
                            new Date(item.data.dispPeriod[0].startDate),
                            "MMMM dd, yyyy"
                          )
                        : ""
                    }}
                  </span>
                </div>
                <div
                  class="flex justify-between items-center gap-2 ml-5 whitespace-nowrap bg-ghostWhite px-3 py-2 rounded-md"
                  v-if="item.data.dispPeriod[1].endDate !== ''"
                >
                  <span class="text-darkPurple text-sm leading-5">
                    {{
                      item.data.dispPeriod[1].endDate !== ""
                        ? $DATEFORMAT(
                            new Date(item.data.dispPeriod[1].endDate),
                            "MMMM dd, yyyy"
                          )
                        : ""
                    }}
                  </span>
                </div>
              </div>
        </template>
      </Table>
    </template>
  </Card>
</template>

<script>
  import Card from "@scelloo/cloudenly-ui/src/components/card";
  import Table from "@scelloo/cloudenly-ui/src/components/table";
  import Icon from "@/components/Icon";

  export default {
    name: "ESSImprovePerformance",
    components: {
      Card,
      Table,
      Icon,
    },
    props: {
      items: {
        type: Array,
        required: true,
        default: (() => [])
      },
      message: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        uploadModal: false,
        timelineModal: false,
        headers: [
          { title: "Performance Issue", value: "performanceIssue", width: 32 },
          { title: "Improvement Action Plan(s)", value: "actionPlan" },
          { title: "Responsible Party(ies)", value: "responsibleParty" },
          { title: "Timeline", value: "dispPeriod" },
        ],
      current: {},
      payloadTimeline: {},
      };
  },

  methods: {},

  mounted() {}

  };
</script>
<style lang="scss">
  .tracking-0015 {
    letter-spacing: 0.015em;
  }
  tbody {
    tr {
      background: white !important;
      border-bottom: 1px dashed #878E99 !important;
    }
    tr:nth-last-child(-n+2) {
      border-bottom: 0 !important;
    }
  }
  .width-272px {
    width: 272px;
  }
</style>

