<template>
  <div>
    <table class="w-full text-sm text-left text-darkPurple mb-3">
      <thead
        class="font-black text-xs text-romanSilver uppercase h-2 bg-white rounded-t-md leading-4"
      >
        <tr class="text-romanSilver mb-4">
          <th scope="col" class="px-6 py-0 h-1"
          v-for="(header, index) in headers"
          :key="index"
          >
          {{header.title}}
          </th>
        </tr>
        <tr class="p-0 !bg-cultured mt-2" v-if="rowHeader">
          <th scope="row" class="px-2 whitespace-nowrap bg-cultured"
          v-for="(header, index) in headers"
          :key="index"
          style="height: 5px; text-transform: capitalize;"
          >
              <td class="flex">
              <slot :name="header.value"/>
              </td>
          </th>
        </tr>
      </thead>
      <tbody class="bg-cultured p-0 my-2">
          <tr class="bg-white border-b border-dashed bg-table" v-for="(item, i) in paginatedData" :key="i">
          <td v-for="(value, key) of populateTable(item)" :key="key">
            <slot name="item" :item="{ [key]: key, data: item, index: i }">
              {{ value }}
            </slot>
          </td>
        </tr>
        <tr v-if="hasFooter" class="w-full" style="background-color: #FFF">
        <slot name="footer" />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "KeyPerformanceArea",
  components: {},
  props: {
    headers: {
      type: Array,
      required: true,
      default: () => []
    },
    rowHeader: {
        type: Boolean,
        default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    hasFooter: {
      type: Boolean,
      default: false
    }
  },

  computed: {
          paginatedData() {
          return this.items;
      },
  },

  methods: {
      populateTable(data) {
        const element = {};
        this.headers.forEach((item) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const key in data) {
            if (key === item.value) {
              element[key] = data[key];
            }
          }
        });
        return element;
      },
  }
};
</script>

<style>
  table {
    border-collapse: collapse;
    width: 100%;
  }
  tbody {
    box-shadow: 0px 1px 3px 0px #0807081a;
    border-radius: 5px;
  }

  td,
  th {
    border: none;
    text-align: left;
    padding: 5px;
    min-height: 1px !important;
  }

  td {
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #321c3b;
  }
  th {
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.035em;
    text-transform: uppercase;
    color: #878e99;
  }
  tr:nth-child(even) {
    background-color: #f7f7ff;
  }
</style>
