<template>
  <Card class="px-3 py-3 flex flex-col w-full gap-3">
    <template>
      <div class="flex justify-start items-center gap-2">
        <h1 class="font-bold text-lg leading-6 tracking-0015 text-flame">Section 2:</h1>
        <h2 class="font-bold text-lg leading-6 tracking-0015 text-darkPurple">
          Criteria for Satisfactory Performance (Post PIP)
        </h2>
      </div>
    </template>
    <template>
      <div class="width-272px bg-ghostWhite text-center rounded-md p-2 flex self-end justify-center items-center">
        <span class="text-darkPurple text-xs font-black leading-5 uppercase">Success Criteria: Min {{criteria}}% - 3.0 of 4</span>
      </div>
    </template>
    <template>
      <div class="relative w-full">
        <scroll-container height="1000px">
          <Table :headers="headers" :items="items" :row-header="true">

            <template v-slot:item="{ item }">
              <div
                v-if="item.objective"
                class="font-normal text-base leading-5 w-80"
              >
                <span class="text-darkPurple">
                  {{ item.data.objective }}
                </span>
              </div>
              <div
                v-if="item.dispPeriod"
                class="flex flex-col justify-center items-start gap-2 whitespace-nowrap mb-4 w-36"
              >
                <div
                  class="flex justify-between items-center gap-2 ml-5 whitespace-nowrap bg-ghostWhite px-3 py-2 rounded-md"
                  v-if="item.data.dispPeriod[0].startDate !== ''"
                >
                  <span class="text-darkPurple text-sm leading-5">
                    {{
                      item.data.dispPeriod[0].startDate !== ""
                        ? $DATEFORMAT(
                            new Date(item.data.dispPeriod[0].startDate),
                            "MMMM dd, yyyy"
                          )
                        : ""
                    }}
                  </span>
                </div>
                <div
                  class="flex justify-between items-center gap-2 ml-5 whitespace-nowrap bg-ghostWhite px-3 py-2 rounded-md"
                  v-if="item.data.dispPeriod[1].endDate !== ''"
                >
                  <span class="text-darkPurple text-sm leading-5">
                    {{
                      item.data.dispPeriod[1].endDate !== ""
                        ? $DATEFORMAT(
                            new Date(item.data.dispPeriod[1].endDate),
                            "MMMM dd, yyyy"
                          )
                        : ""
                    }}
                  </span>
                </div>
              </div>
              <div
                v-if="item.kpis"
            class="text-darkPurple font-normal text-base leading-5"
              >
            <span
              class="mr-2"
              v-for="(value, i) in item.data.kpis"
              :key="`${item}-${i}`"
            >
              {{ value.criteria }};
            </span>

              </div>
              <div
                v-if="item.weight"
                class="flex flex-col justify-start items-start whitespace-nowrap ml-8"
              >
                <div class="flex justify-start items-center gap-2">
                  {{item.data.weight}}
                </div>
              </div>
            </template>
          </Table>
        </scroll-container>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Table from "@/components/Table";
import ScrollContainer from "@/components/ScrollContainer";

export default {
  name: "SatisfactoryPerformance",
  components: {
    ScrollContainer,
    Card,
    Table,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    ratings: {
      type: Array,
      required: true,
      default: () => [],
    },
    message: {
      type: String,
      default: ""
    },

    criteria: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      timelineModal: false,
      headers: [
        { title: "Performance Improvement Action Plan", value: "objective" },
        { title: "Timeline", value: "dispPeriod" },
        { title: "Success Criteria (KPI)", value: "kpis" },
        { title: "Weight (%) = 100%", value: "weight" },
      ],
      current: {},
      payloadTimeline: {},
    };
  },

  methods: {},

  mounted() {}
};
</script>
<style lang="scss">
.tracking-0015 {
  letter-spacing: 0.015em;
}
.summaryTable {
  tbody {
    tr {
      border-top: 1px dashed #878e99 !important;
    }
    tr:first-child {
      border-top: 0 !important;
    }
  }
  .head {
    justify-content: center !important;
  }
}
tbody {
  tr {
    background: white !important;
    border-bottom: 1px dashed #878e99 !important;
  }
  tr:nth-last-child(-n + 2) {
    border-bottom: 0 !important;
  }
}
.width-272px {
  width: 272px;
}
.width-1414px {
  width: 1414px !important;
}
</style>
