<template>
  <Card class="px-3 py-3 flex flex-col w-full gap-3">
    <template>
      <div class="flex justify-start items-center gap-2">
        <h1 class="font-bold text-lg leading-6 tracking-0015 text-flame" v-if="header">
          {{ header }}
        </h1>
        <h2 class="font-bold text-lg leading-6 tracking-0015 text-darkPurple" v-if="title">
          {{ title }}
        </h2>
      </div>
    </template>
    <template>
      <div class="flex flex-col justify-start items-start gap-2">
        <div class="flex flex-col justify-start items-start gap-2">
          <div class="font-normal text-sm leading-5 text-jet" v-if="recommendation">
          <span class="text-semibold text-base leading-5 text-romanSilver">
            Recommended Action: </span>
            <span class="font-semibold">{{ recommendation }}</span>
        </div>
        <h3 class="font-normal text-sm leading-5 text-jet" v-if="isManager">
          Remark/Comment From Manager
        </h3>
        <h3 class="font-normal text-sm leading-5 text-jet" v-if="isLineManager">
          Comments / recommendations / remarks from the employee’s line manager.
        </h3>
        </div>
        <div class="border border-romanSilver border-dashed p-3 rounded-md" v-if="data">
          <p class="font-normal text-base leading-5 text-darkPurple">
            {{ data.comment }}
          </p>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
  import Card from "@scelloo/cloudenly-ui/src/components/card";

  export default {
    name: "EmployeeEngagement",
    components: {
      Card,
    },
    props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
      header: {
        type: String,
        required: true,
        default: ''
      },
      title: {
        type: String,
        required: true,
        default: ''
      },
      recommendation: {
        type: String,
        default: ''
      },
      isManager: {
        type: Boolean,
        default: false
      },
      isLineManager: {
        type: Boolean,
        default: false
      }
    },
  };
</script>
<style lang="scss">
  .tracking-0015 {
    letter-spacing: 0.015em;
  }
</style>

